import { render, staticRenderFns } from "./OrganizationQuestion.vue?vue&type=template&id=438a3dc9&scoped=true&"
import script from "./OrganizationQuestion.vue?vue&type=script&lang=js&"
export * from "./OrganizationQuestion.vue?vue&type=script&lang=js&"
import style0 from "./OrganizationQuestion.vue?vue&type=style&index=0&id=438a3dc9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438a3dc9",
  null
  
)

export default component.exports
<template>
  <div class="Home">
    <page-header/>
    <div class="uk-section uk-padding-small" style="margin-bottom: 60px;">
      <div class="uk-container uk-container-small uk-padding">
        <div class="qList">
          <div class="inform">
            <div class="uk-open">
              <h3>{{ $t("excelUpload") }}</h3>
              <div class="uk-accordion-content">
                <div>
                  <p class="uk-h4">{{ $t("orgaQuestionList.cate") }}</p>
                    <div class="uk-flex" uk-grid>
                      <v-select @input="categoryChange('exam', 'main')"
                                :placeholder="$t('orgaExam.questionManager.main')"
                                v-model="question.examCategory.main" label="name" :reduce="(option) => option.id"
                                :options="examCategory.main" class="uk-width-1-3">
                      </v-select>
                      <v-select @input="categoryChange('exam', 'middle')"
                                v-model="question.examCategory.middle" label="name" :reduce="(option) => option.id"
                                :placeholder="$t('orgaExam.questionManager.mid')"
                                :options="examCategory.middle" class="uk-width-1-3">
                      </v-select>
                      <v-select @input="categoryChange('exam', 'low')"
                                v-model="question.examCategory.low" label="name" :reduce="(option) => option.id"
                                :placeholder="$t('orgaExam.questionManager.low')"
                                :options="examCategory.low" class="uk-width-1-3">
                      </v-select>
                    </div>
                </div>
                <div class="uk-padding uk-padding-remove-left uk-padding-remove-right"
                     style="position: relative">
                  <input type="file" class="dragDrop" @change="previewFileExcel($event)" name="file"
                         accept=".xlsx">
                  <div class="js-upload uk-placeholder uk-text-center">
                    <span uk-icon="icon: cloud-upload" class="uk-margin-small-right"></span>
                    <span class="uk-text-middle" v-if="excel.fileName.length > 0">{{ excel.fileName }}
                        </span>
                    <span class="uk-text-middle" v-else>{{ $t("chEfile") }}</span>
                    <div uk-form-custom>
                      <span class="uk-link uk-margin-small-left">{{ $t("chFile") }}</span>
                    </div>
                  </div>
                  <div>
                    <p class="volume uk-margin-remove">Microsoft office 2008 - 2019</p>
                    <p class="explain uk-margin-remove">{{ $t("orgaQuestionList.mSize") }} : 10MB (XLSX)</p>
                  </div>
                  <div class="uk-flex uk-flex-center uk-padding-small">
                    <div class="uk-flex uk-flex-left">
                      <img src="../../assets/excel.png" alt="" width="60px">
                      <a href="/uploadingDir/QuestionTemplate.xlsx" download="QuestionTemplate.xlsx">
                        <h4 class="uk-margin-remove uk-padding-small">
                          {{ $t("orgaQuestionList.download") }}</h4></a>
                    </div>
                  </div>
                  <button type="button" @click="createQuestionExcel()" style="float: right"
                          class="uk-button uk-button-primary">
                    {{ $t("eupload") }}
                  </button>
                </div>
              </div>
              <h3>{{ $t("zFupload") }}</h3>
              <div class="uk-padding uk-padding-remove-left uk-padding-remove-right"
                   style="position: relative">
                <input type="file" class="dragDrop" @change="previewFileZip($event)" name="file"
                       accept=".zip">
                <div class="js-upload uk-placeholder uk-text-center">
                  <span uk-icon="icon: cloud-upload" class="uk-margin-small-right"></span>
                  <span class="uk-text-middle" v-if="zip.fileName.length > 0">{{ zip.fileName }}
                      </span>
                  <span class="uk-text-middle" v-else>{{ $t("chZfile") }}</span>
                  <div uk-form-custom>
                    <span class="uk-link uk-margin-small-left">{{ $t("chFile") }}</span>
                  </div>
                </div>
                <div>
                  <p class="explain uk-margin-remove">{{ $t("orgaQuestionList.mSize") }} : 200MB (zip)</p>
                </div>
                <div class="uk-flex uk-flex-center uk-padding-small">
                  <div class="uk-flex uk-margin-large-left">
                    <img src="../../assets/zip-file.png" alt="" width="60px">
                    <a href="/uploadingDir/mediafiles.zip" download="zipfiletemplate.zip"
                    ><h4 class="uk-margin-remove uk-padding-small">
                      {{ $t("orgaQuestionList.xdownload") }}</h4></a>
                  </div>
                </div>
                <button type="button" @click="createQuestionZip()" style="float: right"
                        class="uk-button uk-button-primary">
                  {{ $t("zupload") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>
<script>
import QuestionService from "../../services/QuestionService";
import CategoryDataService from "../../services/CategoryDataService";
import {mapGetters} from 'vuex'
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

export default {
  name: 'app',
  components: {
    PageHeader,
    PageFooter,
    vSelect
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },
  data() {
    return {
      excel: {
        excelFile: null,
        fileName: "",
        topCate: "",
        midCate: "",
        lowCate: ""
      },
      zip: {
        zipFile: null,
        fileName: "",
      },
      examCategory: {
        main: [],
        middle: [],
        low: []
      },
      question: {
        examCategory: {
          main: '',
          middle: '',
          low: ''
        },
      },
    };
  },
  mounted() {
    this.loadCates("main", 1, 0)
    this.loadCates("subject", 1, 0)
  },
  methods: {
    categoryChange(type, catelevel) {

      if (type == 'exam') {
        type = 'main'
      } else {
        type = 'subject'
      }

      if (catelevel == 'main') {
        let parentId = 0
        if (type == 'main')
          parentId = this.question.examCategory.main
        else
          parentId = this.question.subjectCategory.main

        this.loadCates(type, 2, parentId)
      }
      if (catelevel == 'middle') {
        let parentId = 0
        if (type == 'main')
          parentId = this.question.examCategory.middle
        else
          parentId = this.question.subjectCategory.middle

        this.loadCates(type, 3, parentId)
      }

    },
    async loadCates(type, cateStep, parentId) {
      try {
        const response = await CategoryDataService.getCategoriesStep({
          type: type,
          cateStep: cateStep,
          parentId: parentId
        })
        if (response.data.status === 200) {
          if (type == "main") {
            //Category
            if (cateStep == 1) {
              this.examCategory.main = response.data.result.cates
            }
            if (cateStep == 2) {
              this.examCategory.middle = response.data.result.cates
            }
            if (cateStep == 3) {
              this.examCategory.low = response.data.result.cates
            }
          } else {
            //subject category
            if (cateStep == 1) {
              this.subjectCategory.main = response.data.result.cates
            }
            if (cateStep == 2) {
              this.subjectCategory.middle = response.data.result.cates
            }
            if (cateStep == 3) {
              this.subjectCategory.low = response.data.result.cates
            }
          }

        } else {
          alert("ERROR: " + response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },

    async createQuestionExcel() {
      try {
        let formData = new FormData()
        if (this.excel.fileName == '') {
          alert("choose excel questions")
          return;
        }

        if (this.question.examCategory.main == '0') {
          alert("select top category")
          return;
        }
        if (this.question.examCategory.middle == '0') {
          alert("select middle category")
          return;
        }
        if (this.question.examCategory.low == '0') {
          alert("select low category")
          return;
        }
        formData.append('efile', this.excel.excelFile)
        formData.append('topcate', this.question.examCategory.main)
        formData.append('midcate', this.question.examCategory.middle)
        formData.append('lowcate', this.question.examCategory.low)
        formData.append('organizId', this.currentUser.organizs[0].organizId)
        const response = await QuestionService.createQuestionExcel(formData)
        if (response.data.status === 200) {
          alert("Success")
          this.excel.excelFile = null
          this.excel.fileName = ""
          this.$router.push({name: 'OrganizationQuestionList', params: {id: 0}})
        } else {
          alert(response.data.message)
        }
      } catch (e) {
        console.log(e)
        alert(e.response.data.message)
      }
    },
    async createQuestionZip() {
      try {
        let formData = new FormData()
        if (this.zip.fileName == '') {
          alert("choose zip file")
          return;
        }
        formData.append('zfile', this.zip.zipFile)
        const response = await QuestionService.createQuestionZip(formData)
        if (response.data.status === 200) {
          alert(response.data.message)
          this.zip.zipFile = null
          this.zip.fileName = ""
          this.$router.push({name: 'OrganizationQuestionList', params: {id: 0}})
        } else {
          alert(response.data.message)
        }
      } catch (e) {
        console.log(e)
        alert(e.response.data.message)
      }
    },
    previewFileExcel(event) {
      this.excel.fileName = ''
      this.excel.excelFile = ''

      var FileSize = event.target.files[0].size / 1024 / 1024;
      if (FileSize > 10) {
        alert("Maximum size is 10.")
        return
      }
      this.excel.excelFile = event.target.files[0]
      this.excel.fileName = event.target.files[0].name
      if (this.excel.excelFile) {
        if (/\.(XLSX)$/i.test(this.excel.fileName)) {
          console.log("nice")
        } else {
          alert("File format is incorrect. Please check your file!");
          this.excel.fileName = ''
          this.excel.excelFile = ''
          return
        }
      }
    },
    previewFileZip(event) {
      this.zip.zipFile = ''
      this.zip.fileName = ''

      var FileSize = event.target.files[0].size / 1024 / 1024;
      if (FileSize > 200) {
        alert("Maximum size is 200MB.")
        return
      }
      this.zip.zipFile = event.target.files[0]
      this.zip.fileName = event.target.files[0].name
      if (this.zip.excelFile) {
        if (/\.(ZIP)$/i.test(this.zip.fileName)) {
          console.log("nice")
        } else {
          alert("File format is incorrect. Please check your file!");
          this.zip.fileName = ''
          this.zip.zipFile = ''
          return
        }
      }
    },
  }
}

</script>
<style scoped>
.uk-container {
  background: white;
}

.uk-placeholder {
  background: #E6E6E6;
}

.qList h3 {
  height: 30px;
  margin-right: 20px;
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: 900;
  color: rgb(1, 176, 255);
  padding-left: 15px;
  border-left: 5px solid #0d9df9;
}

.qList .uk-h4 {
  font-size: 1.1em;
  font-weight: 600;
  color: rgb(1, 176, 255);
  padding-left: 15px;
  border-left: 5px solid #0d9df9;
}

.select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.select-dropdown {
  position: relative;
  background-color: #E6E6E6;
  border-radius: 4px;
  margin-right: 10px;
}

.select-dropdown select {
  font-size: 1rem;
  font-weight: normal;
  width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-dropdown select:active, .select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.select-dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.dragDrop {
  width: 100%;
  height: 80px;
  align-items: center;
  text-align: center;
  opacity: 0;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 64px;
}

.vs__clear {
  display: none !important;
}

.vs__clear {
  display: none !important;
}

svg:not(:root) {
  overflow: hidden;
  width: 15px !important;
  height: 10px !important;
}

.select-dropdown:after {
  display: none;
}

.vs__dropdown-menu{
  width: 100% !important;
}
</style>
